var currentTime;
var purchaseWidth;
var times;
var lastTime;
var currentTime;
var oldTarget = 0;
var cigarettes;

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelectorAll('.smoking-calculator-timeframes__item').length !== 0) {
    times = document.querySelectorAll('.smoking-calculator-timeframes__item');
    lastTime = times.length - 1;
    currentTime = index(times[0]);
  }
});

document.addEventListener('click',
  function (event) {
    if (event.target.matches('.smoking-calculator__submit')) {
      smokingCalculator();
      event.preventDefault();
    }
    if (event.target.matches('.smoking-calculator__revert')) {
      revertSmokingCalculator();
      event.preventDefault();
    }
    if (event.target.matches('.smoking-calculator-timeframes__next, .smoking-calculator-timeframes__next *')) {
      currentTime === lastTime ? currentTime = 0 : currentTime++;
      sliderResponse(currentTime);
      event.preventDefault();
    }
    if (event.target.matches('.smoking-calculator-timeframes__prev, .smoking-calculator-timeframes__prev *')) {
      currentTime === 0 ? currentTime = lastTime : currentTime--;
      sliderResponse(currentTime);
      event.preventDefault();
    }
  },
  false
);

function smokingCalculatorPrettyCost(nStr) {
  nStr += '';
  x = nStr.split('.');
  x1 = x[0];
  x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
}

function index(element) {
  var sib = element.parentNode.childNodes;
  var n = 0;
  for (var i = 0; i < sib.length; i++) {
    if (sib[i] == element) return n;
    if (sib[i].nodeType == 1) n++;
  }
  return -1;
}

function eq(index) {
  if (index >= 0 && index < this.length)
    return this[index];
  else
    return -1;
}

function hideCalculatorItems() {
  document.querySelector('.smoking-calculator-calculation__coin').style.opacity = 0;
  document.querySelector('.smoking-calculator-calculation__coin').style.top = '-20px';
  document.querySelector('.smoking-calculator-calculation__total').style.opacity = 0;
  document.querySelector('.smoking-calculator-calculation__total').style.top = '55%';
}

function revertSmokingCalculator() {
  document.querySelector('.smoking-calculator').classList.remove('smoking-calculator--flipped');
}

function smokingCalculator() {
  var costPerPacket = parseInt(document.querySelector('#cost_of_20_cigarettes').value);
  console.log('Cost ' + costPerPacket);
  var cigarettes = parseInt(document.querySelector('#cigarettes_per_day').value);
  console.log('Cigarettes ' + cigarettes);
  var days = eq.call(times, currentTime).dataset.days;
  var savings = ((cigarettes / 20 * costPerPacket) * days).toFixed(2);

  hideCalculatorItems();

  total = smokingCalculatorPrettyCost(savings);
  numeric = Math.ceil(savings);

  document.querySelector('.smoking-calculator').classList.add('smoking-calculator--flipped');

  var purchaseWidth = 0;
  var elements = document.querySelectorAll('.smoking-calculator-purchases__item');
  for (var i = 0, n = elements.length; i < n; i++) {
    if (elements[i].clientWidth > purchaseWidth) {
      purchaseWidth = elements[i].clientWidth;
    }
  };

  document.querySelector('.smoking-calculator-purchases').style.width = purchaseWidth + 'px';
  elements = document.querySelectorAll('.smoking-calculator-purchases__item');
  for (var i = 0, n = elements.length; i < n; i++) {
    elements[i].style.width = purchaseWidth + 'px';
  };

  var purchaseItemsLength = document.querySelectorAll('.smoking-calculator-purchases__item').length;
  var wrapWidth = purchaseWidth * purchaseItemsLength;
  document.querySelector('.smoking-calculator-purchases__wrapper').style.width = wrapWidth + 'px';

  var purchasesWrapper = document.querySelector('.smoking-calculator-purchases__wrapper');

  var purchaseItems = document.querySelectorAll('.smoking-calculator-purchases__item'), i;

  var rangeFound = false;

  for (i = 0; i < purchaseItems.length; ++i) {
    let fromAmount = purchaseItems[i].dataset.calculatorFrom;
    let toAmount = purchaseItems[i].dataset.calculatorTo;

    if(numeric >= fromAmount && numeric <= toAmount && rangeFound == false) {
      purchasesWrapper.style.left = '-' + parseInt(purchaseWidth * i) + 'px';
      rangeFound = true;
    }
  }

  if(rangeFound == false) {
    purchasesWrapper.style.left = '-' + parseInt(purchaseWidth * (purchaseItemsLength - 1)) + 'px';
  }

  setTimeout(function () {
    document.querySelector('.smoking-calculator-calculation__coin').style.opacity = 1;
    document.querySelector('.smoking-calculator-calculation__coin').style.top = '4px';
  }, 500);

  setTimeout(function () {
    document.querySelector('.smoking-calculator-calculation__total').innerHTML = '&pound;' + total;
    document.querySelector('.smoking-calculator-calculation__coin').style.opacity = 0;
    document.querySelector('.smoking-calculator-calculation__total').style.top = '52%';
    document.querySelector('.smoking-calculator-calculation__total').style.opacity = 1;
  }, 750, total);
}

function isHidden(el) {
  var style = window.getComputedStyle(el);
  return (style.opacity == 0)
}

function sliderResponse(target) {
  hideCalculatorItems();

  var elements = document.querySelectorAll('.smoking-calculator-timeframes__item--active');
  for (var i = 0, n = elements.length; i < n; i++) {
    elements[i].classList.remove('smoking-calculator-timeframes__item--active');
  };

  eq.call(times, currentTime).classList.add('smoking-calculator-timeframes__item--active');

  if (target > oldTarget) {
    document.querySelector('.smoking-calculator-timeframes__list').style.left = (92 - (index(eq.call(times, currentTime)) * 92)) + 'px';
  } else {
    document.querySelector('.smoking-calculator-timeframes__list').style.left = (92 - (index(eq.call(times, currentTime)) * 92)) + 'px';
  }

  if (target == lastTime) {
    document.querySelector('.smoking-calculator-timeframes__next').classList.add('smoking-calculator-timeframes__next--hidden');
  } else if (target != lastTime && isHidden(document.querySelector('.smoking-calculator-timeframes__next'))) {
    document.querySelector('.smoking-calculator-timeframes__next').classList.remove('smoking-calculator-timeframes__next--hidden');
  }

  if (target == 0) {
    document.querySelector('.smoking-calculator-timeframes__prev').classList.add('smoking-calculator-timeframes__prev--hidden');
  } else if (target != 0 && isHidden(document.querySelector('.smoking-calculator-timeframes__prev'))) {
    document.querySelector('.smoking-calculator-timeframes__prev').classList.remove('smoking-calculator-timeframes__prev--hidden');
  }

  oldTarget = target;

  smokingCalculator();
}
