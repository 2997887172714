import $ from 'jquery';
require('slick-carousel/slick/slick');

$(function() {
  $('.home-testimonials').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: 'home-testimonials-dots'
  });

  $('.service-testimonials').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: 'service-testimonials-dots'
  });

  $('.home-banners, .service-banners, .kidzone-banners').slick({
    dots: true,
    arrows: false,
    autoplay: true,
    fade: true,
    autoplaySpeed: 4000
  });
});

